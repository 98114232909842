import { changeLife } from "./damage";
import { checkDebuffTurn } from "./debuff";
import { log } from "./log";

export function regenarate(target) {
  if (target == null) {
    return;
  }
  if (!checkDebuffTurn(target, "rot")) {
    checkRegeneration(target);
  } else {
    log(
      "chances",
      `${target.name} can't regenerate due to <span style='color:crimson'>rot</span`
    );
  }
}

function checkRegeneration(target) {
  if (target.regeneration <= 0) {
    return;
  }

  let ammount =
    target.status.bury > 0 ? target.regeneration / 6 : target.regeneration / 25;

  if (target.status.invert > 0) {
    changeLife(target, ammount, "invert", "damage");
  } else {
    if (target.clife + ammount >= target.life) {
      ammount = target.life - target.clife;
    }
    if (ammount <= 0) {
      return;
    }

    changeLife(target, ammount, "regeneration", "heal");
  }
}
