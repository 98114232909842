import store from "@/store/index";

const colors = {
  fire: "orange",
  bleed: "crimson",
  poison: "lightgreen",
  crit1: "brown",
  crit2: "yellow",
  crit4: "purple",
  crit8: "red",
  crit16: "chartreuse",
  lifesteal: "darkred",
  reflect: "gold",
  magic: "blue",
};

export function showIndicator(amount, type) {
  if (amount <= 0) {
    return;
  }
  const color = colors[type];
  store.state.dmgind.push({ text: amount, color: color });
  setTimeout(() => {
    try {
      store.state.dmgind.shift();
    } catch {
      null;
    }
  }, 500);
}

export function animateObject(source) {
  try {
    const enemyElement = document.getElementById("enemy");
    enemyElement.classList.add(source);
    setTimeout(() => {
      enemyElement.classList.remove(source);
    }, 500);
  } catch {
    null;
  }
}
