export var p = {
  name: "Rimuro",
  speed: 2500,
  sspeed: 0,
  dmg: 1,
  cspeed: 0,
  magic: 1,
  life: 10,
  clife: 10,
  regeneration: 0,
  recovery: 1,
  resistance: {},
  chance: {},
  effects: {},
  counter: {},
  skills: [],
  status: {},
  items: [],
  maxitems: 1,
  unlocked: [],
  allcount: {},
  time: 0,
  auto: false,
  lastEnemy: "bat",
  version: "1.13",
  highscore: {},
  tutorial: 0,
  prestige: 0,
  finishtime: 0,
  points: 0,
  go: false,
  order: [],
  quests: [],
  challenge: null,
  challenges: {},
  gold: 0,
};
