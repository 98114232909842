<template>
  <div
    class="fullsize"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/icons/background.webp') + ')',
    }"
  >
    <div class="loadmid">
      <div class="rotate">
        <img :src="require('@/assets/images/icons/hero.webp')" alt="loading" />
      </div>
      <span style="font-size: 40px">LOADING</span>
    </div>
  </div>
</template>

<script>
export default { name: "LoadItem" };
</script>


<style scoped>
.loadmid {
  padding: 10px;
  border: 1px solid black;
  background-color: lightgray;
  border-radius: 5px;
  box-shadow: inset 0 0 4px grey;
  text-align: center;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 200px);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate img {
  image-rendering: pixelated;
  width: 200px;
  height: 200px;
  animation: rotate 1s infinite;
}
</style>
