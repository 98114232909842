import { preloadJson, preloadImages } from "@/assets/scripts/loading";
import { recover, getNextEnemy, getEnemy } from "@/assets/scripts/game";
import { getPlayer, savePlayer } from "@/assets/scripts/exportImport";
import { update } from "@/assets/scripts/fight/main";
import router from "@/router/index";
import { secondsToTime } from "@/assets/scripts/helper";
import prestigeList from "@/assets/json/prestigeList";
export default {
  toggleHide({ commit, state }) {
    commit("setHidden", !state.hidden);
  },
  hideBar({ state }, name) {
    if (state.statBar[name] == undefined) {
      state.statBar[name] = false;
    } else {
      state.statBar[name] = !state.statBar[name];
    }
  },
  gameWon({ state, dispatch }) {
    state.player.go = true;
    state.player.auto = false;
    state.player.finishtime = secondsToTime(state.player.time);
    dispatch("displayFinish");
  },
  exportOrder({ state, dispatch }) {
    let ov = state.overlay.normal.$data;
    ov.text = state.player.order;
    ov.order = true;
    ov.copy = false;

    ov.obj = [{ text: "Ok", func: () => dispatch("closeOverlay", true) }];
    ov.img = "icons/export";

    dispatch("closeOverlay", false);
  },
  playerDied({ dispatch }) {
    dispatch("spawnEnemy", null);
  },
  setOrder({ commit, dispatch }, order) {
    commit("setOrder", order);
    dispatch("makeOrder");
  },
  nextStage({ dispatch, state }) {
    if (state.player.clife == state.player.life && state.player.auto) {
      dispatch("setNextEnemy");
    } else {
      dispatch("playerDied");
    }
  },
  startRecover({ dispatch, commit, state }) {
    if (state.timer.recovery != null) {
      clearInterval(state.timer.recovery);
    }
    let timer = setInterval(
      () =>
        dispatch("recover").then(() => {
          commit("clearRecoveryTimer");
        }),
      state.GameSpeed
    );

    commit("setTimerRecovery", timer);
  },
  prestige({ state, dispatch }) {
    state.player.prestgeUp();
    dispatch("save");
    dispatch("closeOverlay", true);
    dispatch("closeSkillTree", false);
  },

  prestigeChallenge({ state, dispatch, commit }) {
    state.player.prestgeUp();

    if (state.player.challenges[state.player.challenge] == undefined) {
      state.player.challenges[state.player.challenge] = 0;
    }

    if (state.player.challenges[state.player.challenge] < 10) {
      state.player.challenges[state.player.challenge]++;
    }

    commit("abbortChallenge");

    dispatch("save");
    dispatch("closeOverlay", true);
    dispatch("closeSkillTree", false);
  },

  startChallenge({ state, commit, dispatch }, val) {
    commit("startChallenge", val);
    state.player.softReset();
    dispatch("spawnEnemy", null);
  },

  backgroundCheck({ state, dispatch }) {
    if (state.enemy == null) {
      return;
    }

    let now = Date.now();
    let diff = now - state.idleTime;
    if (diff >= 300000) {
      diff = 300000;
    }

    for (let i = 0; i < diff; i += state.GameSpeed) {
      dispatch("updateFight");
    }

    state.idleTime = now;
  },

  updateFight({ state }) {
    if (state.enemy == null) {
      return;
    }
    if (state.player == null) {
      return;
    }
    update(state.player, state.enemy);
    update(state.enemy, state.player);
  },

  startFight({ state, commit, dispatch }) {
    commit("clearFightTimer");

    state.idleTime = Date.now();

    if (state.timer.fight == null) {
      let t1 = setInterval(() => {
        dispatch("backgroundCheck");
      }, state.GameSpeed);

      commit("setFightTimer", t1);
    }
  },
  setNextEnemy({ dispatch }) {
    let enemy = getNextEnemy();
    dispatch("spawnEnemy", enemy);
  },
  cheat({ state, commit }, { value: value, max: max }) {
    state.player.counter[value.id] = max;
    state.player.allcount[value.id] += max;
    commit("recalculatePlayer");
  },
  recover({ state, dispatch }) {
    return recover(state.player).then(() => {
      state.player.auto && dispatch("setNextEnemy");
    });
  },
  resetOrder({ getters, dispatch }) {
    let ord = getters.checkBuffedEnemies
      .map(({ id: a }) => a)
      .filter((x) => !x.secret);
    dispatch("setOrder", ord);
  },
  makeOrder({ state, getters, commit }) {
    let list = [];

    for (let a of state.player.order) {
      list.push(getters.checkBuffedEnemies.find((b) => a == b.id));
    }

    commit("setEnemyOrder", list);
  },
  spawnEnemy({ commit, dispatch }, t) {
    if (t == null) {
      router.currentRoute.value.name == "Fight" && router.push("dungeon");
      dispatch("startRecover");
      commit("setEnemy", null);
    } else {
      commit("setEnemy", getEnemy(t));
      commit("clearRecoveryTimer");
      dispatch("startFight");
    }
  },
  preload({ commit }) {
    return preloadJson().then((e) => {
      commit("setData", e);
      return preloadImages(e).then((d) => {
        commit("setImages", d);
      });
    });
  },
  load({ commit, dispatch }, p) {
    let player = getPlayer(p);
    commit("setPlayer", player);
    dispatch("initTimer");
    dispatch("initKeys");
  },
  initKeys({ state }) {
    window.addEventListener("keydown", (e) => {
      e.ctrlKey && (state.cntrlIsPressed = true);
      e.shiftKey && (state.shiftIsPressed = true);
    });

    window.addEventListener("keyup", (e) => {
      !e.ctrlKey && (state.cntrlIsPressed = false);
      !e.shiftKey && (state.shiftIsPressed = false);
    });
  },
  setSpeed({ commit, state, dispatch }, speed) {
    commit("setSpeed", speed);

    dispatch("initTimer");
    if (state.enemy == null) dispatch("startRecover");
  },
  initTimer({ dispatch, commit, state }) {
    if (state.timer.clock != null) {
      clearInterval(state.timer.clock);
    }
    let clockt = setInterval(() => commit("countTime"), state.GameSpeed);
    commit("setTimerClock", clockt);

    if (state.timer.save != null) {
      clearInterval(state.timer.save);
    }
    let savet = setInterval(() => dispatch("save"), state.GameSpeed * 30);
    commit("setTimerSave", savet);
  },
  save({ state }) {
    savePlayer(state.player);
  },
  setOverlay({ commit }, { ov: overlay, st: skilltree }) {
    let obj = {};
    obj.normal = overlay;
    obj.skilltree = skilltree;
    commit("setOverlay", obj);
  },
  closeSkillTree({ state }, val) {
    state.showSkillTree = !val;
  },
  closeOverlay({ state }, val) {
    state.showOverlay = !val;
  },
  closeSpecial({ state, dispatch }, val) {
    dispatch("spawnEnemy", null);
    val && dispatch("makeOrder");
    state.showSpecialOrder = !val;
  },
  softReset({ state, dispatch }) {
    state.player.softReset();
    dispatch("save");
    dispatch("closeOverlay", true);
  },
  hardReset({ state, dispatch }) {
    state.player.hardReset();
    dispatch("save");
    dispatch("closeOverlay", true);
  },
  showHardReset({ state, dispatch }) {
    let ov = state.overlay.normal.$data;
    ov.order = false;
    ov.text =
      "Do you really want to wipe your entire save? <br /> (There is no reason to do this)";
    ov.obj = [
      { text: "Yes", func: () => dispatch("hardReset") },
      { text: "No", func: () => dispatch("closeOverlay", true) },
    ];
    ov.img = "icons/reset";
    dispatch("closeOverlay", false);
  },
  showSoftReset({ state, dispatch }) {
    let ov = state.overlay.normal.$data;
    ov.text = "Do you really want to wipe your current run?";
    ov.order = false;
    ov.obj = [
      { text: "Yes", func: () => dispatch("softReset") },
      { text: "No", func: () => dispatch("closeOverlay", true) },
    ];
    ov.img = "icons/softreset";
    dispatch("closeOverlay", false);
  },
  displayFinish({ state, dispatch }) {
    let ov;
    try {
      ov = state.overlay.normal.$data;
    } catch {
      const overlayElement = document.getElementById("overlay");
      ov = overlayElement && overlayElement.$data;
    }

    ov.order = false;

    let boni = prestigeList.find((x) => x.id == state.player.prestige + 1);

    let obj = [];
    if (state.player.challenge != null) {
      ov.text = `<h3>You finished the challenge!</h3><br> It took you ${state.player.finishtime}`;

      obj = [
        { text: "continue", func: () => dispatch("closeOverlay", true) },
        { text: "Finish Challenge", func: () => dispatch("prestigeChallenge") },
      ];
    } else {
      ov.text = `<h3>You finished the game!</h3>It took you ${
        state.player.finishtime
      }. You are Prestige ${state.player.prestige + 1}`;
      if (boni) {
        ov.text += "<br>Next Bonus:<ul>";
        for (let b of boni.features) {
          ov.text += "<li>" + b + "</li>";
        }
        ov.text += "</ul>";
      }
      obj = [
        { text: "continue", func: () => dispatch("closeOverlay", true) },
        { text: "prestige", func: () => dispatch("prestige") },
      ];
    }

    ov.img = "icons/finish";
    ov.obj = obj;

    dispatch("closeOverlay", false);
  },
};
