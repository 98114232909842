import store from "@/store/index";
import { changeLife } from "./damage";
import { RoundToInt } from "../helper";
import { checkChance, checkAbilityChance } from "./main";
import { log, logChance, logChanceDamage } from "./log";

const buffs = ["stim", "bury", "ward"];
const dot = ["poison", "fire", "bleed"];

export function checkDebuffs(target, source, type) {
  if (target == null || source == null) {
    return;
  }
  checkCleanse(source);

  checkDebuff(target, source, type, "poison", 40, "green");
  checkDebuff(target, source, type, "fire", 30, "orange");
  checkDebuff(target, source, type, "bleed", 100, "crimson");
}

export function checkSpeedDebuffs(target) {
  0 < target.status.slow && target.status.slow--;
  0 < target.status.stim && target.status.stim--;
}

export function checkDebuffTurn(target, name) {
  if (target.status[name] > 0) {
    if (name == "rot") {
      target.status[name] -= 0.1;
    } else {
      target.status[name]--;
    }

    return true;
  }
  return false;
}

export function setDebuffs(target, source) {
  for (let buff of store.state.data.buffs) {
    if (checkDebuffTurn(source, "ward")) {
      return;
    }

    let self = buffs.includes(buff);
    setBuff(buff, target, source, self);
  }
}

function checkDebuff(target, source, type, name, p, color) {
  if (target.status[name] > 0) {
    let damage = source.magic / p;
    damage -= getResistance(target, damage, name, color);

    logChanceDamage(name, target, damage);

    changeLife(target, damage, name, "damage", type);

    let add;
    if (dot.includes(name)) {
      add = 0.1;
    } else {
      add = 1;
    }

    target.status[name] -= add;
  }
}

function getResistance(target, damage, name, color) {
  if (target.resistance && target.resistance[name + "resistance"]) {
    let res = RoundToInt(
      damage * (target.resistance[name + "resistance"] / 100)
    );
    if (res > 0.0)
      log(
        "DOT",
        `${target.name} resisted<span style="color:${color}"> ${res} ${name}</span> damage`
      );
    return res;
  }
  return 0;
}

function setBuff(name, target, source, buff = false) {
  if (!target.chance || !target.chance[name]) {
    return;
  }

  let chance = target.chance[name];

  if (buff) {
    addChance(chance, target, name);
  } else {
    addChance(chance, source, name);
  }
}

function addChance(chance, target, name) {
  if (chance >= 100) {
    let times = (chance - (chance % 100)) / 100;
    checkChance(chance % 100) && times++;
    target.status[name] += times;
    logChance(name, target, times);
  } else if (checkChance(chance)) {
    target.status[name]++;
    logChance(name, target, 1);
  }
}

function checkCleanse(source) {
  if (checkAbilityChance(source, "cleanse")) {
    log("chances", `${source.name} cleansed himself `);
    let tempBuff = Object.entries(source.status).filter((x) =>
      buffs.includes(x[0])
    );
    source.resetStatus();
    for (let buff of tempBuff) {
      source.status[buff[0]] = buff[1];
    }
  }
}
