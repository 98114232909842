<template>
  <div :class="{ beta: beta }">
    <div v-if="loaded">
      <Navigation />

      <router-view />
      <Playerbar />
      <div>
        <Overlay v-show="showOverlay" ref="overlay" id="overlay" />
        <Skilltree v-show="showSkillTree" ref="SkillTree" />
        <!--  <SpecialOrder v-show="showSpecialOrder" ref="specialOrder" /> -->
      </div>
    </div>
    <Load v-else />
  </div>
</template>

<script>
/* 
import Navigation from "@/components/Navigation.vue"; 
import Playerbar from "@/components/Playerbar/Playerbar.vue";
import Overlay from "@/components/Overlay.vue";
import SkillTree from "@/components/Stats/SkillTree.vue"; 
*/

import Load from "@/components/Load.vue";
import { mapState } from "vuex";
import { defineAsyncComponent } from 'vue'


export default {
  data: () => ({
    loaded: false,
  }),
  computed: {
    ...mapState([
      "overlay",
      "player",
      "showOverlay",
      "showSkillTree",
      "showSpecialOrder",
    ]),
  },
  components: {
    Load,
    Navigation: defineAsyncComponent(() =>
      import('./components/Navigation.vue')
    ),
    Playerbar: defineAsyncComponent(() =>
      import('./components/Playerbar/Playerbar.vue')
    ),
    Overlay: defineAsyncComponent(() =>
      import('./components/Overlay.vue')
    ),
    Skilltree: defineAsyncComponent(() =>
      import('./components/Stats/Skilltree.vue')
    ),
  },
  name: "AppItem",
  created() {

    this.$store.dispatch("preload").then(() => {
      this.$store.dispatch("load").then(() => {
        this.loaded = true;

      });
    });
  },
  mounted() {
    this.setReference();
  },
  methods: {
    setReference() {
      if (Object.keys(this.$refs).length == 0) {
        setTimeout(() => this.setReference(), 100);
      }
      this.$nextTick(() => {
        let ov = this.$refs.overlay;
        let st = this.$refs.SkillTree;
        this.$store.dispatch("setOverlay", { ov, st });
      });
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');

body,
html,
#app {
  user-select: none;
  background: grey;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: "MedievalSharp", cursive;
}

.hide {
  display: none;
}

.beta {
  border: 1px solid red;
}

.fullsize {
  height: 100%;
  min-height: 100vh;
  padding: 30px 10px 80px 10px;
}

.btn {
  line-height: 32px;
  border-radius: 5%;
  font-size: 20px;
  font-family: "MedievalSharp", cursive;
  margin: 10px;
  padding: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  background: #a5a5a5;
  transition: 0.1s;
  box-shadow: 0 5px #636363;
  display: block;
  border: 0.5px solid darkslategrey;

  img {
    float: left;
    width: 32px;
    height: 32px;
  }

  &:active,
  &.active {
    box-shadow: 0 2px #054d7a;
    transform: translateY(1px);
    background: #0a9bf5;
  }

  &:hover {
    background: #7a7a7a;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-colum {
  display: flex;
  flex-direction: column;
}

.dun {
  min-width: 130px;
  background: lightskyblue;
  font-size: 14px;
  display: inline;
  padding: 4px;
  box-shadow: 0 2px #0a9bf5;
  line-height: 20px;

  &:hover {
    background: #9dd7fb;
  }

  &:active,
  &.active {
    box-shadow: 0 2px #054d7a;
    transform: translateY(1px);
    background: #0a9bf5;
  }

  &>img {
    vertical-align: middle;
    height: 20px;
    width: 20px;
  }
}

.text {
  background: lightgray;
  margin-left: 10px;
  padding: 20px;
  display: block;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: inset 0 0 4px grey;
}

/* Scrollbar Global */
::-webkit-scrollbar {
  width: 15px;
}

.valbox {
  margin: 5px;
  padding: 2px;
  width: 80px;
  white-space: nowrap;
  border: 0.5px solid black;
  background: gold;
}

::-webkit-scrollbar-track {
  background: #777;
}

::-webkit-scrollbar-thumb {
  background: #474747;
}

::-webkit-scrollbar-thumb:hover {
  background: #616161;
}


.btn.shiny {
  position: relative;
  overflow: hidden;
}

.btn.shiny::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
  transform: rotate(45deg);
  animation: shiny 3s linear infinite;
}

ul {


  li {
    font-size: 20px;
    text-align: left;
  }
}

@keyframes shiny {
  0% {
    transform: rotate(45deg) translate(-200%, -200%);
  }

  100% {
    transform: rotate(45deg) translate(200%, 200%);
  }
}

@media screen and (max-width: 600px) {
  .hideMobile {
    display: none;
  }
}
</style>
