export default {
  overlay: {
    normal: null,
    skilltree: null,
    special: null,
  },
  showOverlay: false,
  showSkillTree: false,
  showSpecialOrder: false,
  data: null,
  player: null,
  enemy: null,
  recover: false,
  log: [],
  GameSpeed: 100,
  images: null,
  shiftIsPressed: false,
  cntrlIsPressed: false,
  dmgind: [],
  timer: {},
  search: "",
  hidden: true,
  enemyOrder: null,
  isMobile: false,
  setIdleTime: null,
  openedLog: {
    openedChange: false,
    chance: false,
    effects: false,
    crit: false,
    attacks: false,
    death: false,
    dot: false,
    detail: false,
  },
  dragEl: null,
  cstats: {},
  statBar: {
    questFinish: true,
    base: true,
    chances: true,
    effects: true,
    resistance: true,
    side: true,
    counter: true,
    highscore: true,
    items: true,
    skills: true,
    all: true,
    chal: true,
  },
};
