export function preloadImages(e) {
  return new Promise((resolve) => {
    let images = [];

    var requireImage = require.context(
      "@/assets/images/enemys/",
      false,
      /\.webp$/
    );

    for (let a of e.enemys)
      images.push({
        id: a.id,
        img: requireImage("./" + a.id + ".webp"),
      });

    requireImage = require.context("@/assets/images/skills/", false, /\.webp$/);

    for (let a of e.tipps)
      try {
        images.push({
          id: a.id,
          img: requireImage("./" + a.id + ".webp"),
        });
      } catch (error) {
        console.error(error);
      }

    requireImage = require.context("@/assets/images/items/", false, /\.webp$/);

    for (let a of e.items)
      images.push({
        id: a.id,
        img: requireImage("./" + a.id + ".webp"),
      });

    requireImage = require.context("@/assets/images/buffs/", false, /\.webp$/);

    for (let a of e.buffs)
      images.push({
        id: "b" + a,
        img: requireImage("./" + a + ".webp"),
      });

    resolve(images);
  });
}

export function preloadJson() {
  return new Promise((resolve) => {
    let arr = [],
      items = [
        "enemys",
        "tipps",
        "choises",
        "changelog",
        "buffs",
        "skilltree",
        "items",
        "quests",
        "challenges",
      ],
      obj = {};

    for (let item of items) arr.push(import("@/assets/json/" + item + ".json"));

    Promise.all(arr).then((e) => {
      for (let i in items) obj[items[i]] = e[i].default;

      resolve(obj);
    });
  });
}
