import { setDebuffs, checkDebuffTurn } from "../debuff";
import { checkAbilityChance } from "../main";
import { changeLife } from "../damage";
import { log } from "../log";

export function checkSpells(target, source) {
  if (!checkDebuffTurn(target, "silence")) {
    setDebuffs(target, source);

    if (!checkDebuffTurn(source, "ward")) {
      checkMagicMissle(target, source);
    }

    checkMagicHeal(target);
  } else {
    log(
      "chances",
      `${target.name} can't cast Spells he's <span style='color:grey'>silenced</span>`
    );
  }
}

function checkMagicMissle(target, source) {
  if (checkAbilityChance(target, "magicmissile")) {
    changeLife(source, target.magic / 10, "magic", "damage");
    log(
      "chances",
      `${
        target.name
      } shoots <span style='color:lightblue'>Magic Missle</span> dealing ${
        target.magic / 10
      } Damage`
    );
  }
}

function checkMagicHeal(target) {
  if (checkAbilityChance(target, "heal")) {
    changeLife(target, target.magic / 10, "magic", "heal");
    log(
      "chances",
      `${
        target.name
      }  <span style='color:lightgreen'>heals himself</span> healing ${
        target.magic / 10
      } life`
    );
  }
}
