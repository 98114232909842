import store from "@/store/index";
import { getLast } from "../helper";
import { getLastBoss, SingleCalculation } from "../game";
import { animateObject } from "./display";
import { checkAbilityChanceWithNegativ } from "./main";
import { log } from "./log";

export function checkDeath(target, source, ammount) {
  if (target == null) {
    return true;
  }
  if (target.version != null) {
    if (source.clife <= 0) {
      checkEnemyDeath(target, source, ammount);
      return false;
    }
    if (target.clife <= 0) {
      checkPlayerDeath(target, source);
      return false;
    }
  } else {
    if (source.clife <= 0) {
      checkPlayerDeath(source, target);
      return false;
    }
    if (target.clife <= 0) {
      checkEnemyDeath(source, target, ammount);
      return false;
    }
  }
  return true;
}

function checkPlayerDeath(target, source) {
  if (checkAbilityChanceWithNegativ(target, "resurrect", source, "godkill")) {
    log("chances", `${target.name} resurrected`);
    target.respawn();
    return;
  }

  log("death", `<hr/>${source.name} killed  ${target.name}`);

  store.dispatch("playerDied");
}

function checkOverkill(source, ammount) {
  let n = Math.floor(ammount / source.life);
  let difficultyMultiplier = 1 + (n - 1) * 0.1;
  n = Math.round(n / difficultyMultiplier);
  return n >= 1 ? n : 1;
}

function checkEnemyDeath(target, source, ammount) {
  if (checkAbilityChanceWithNegativ(source, "resurrect", target, "godkill")) {
    log("chances", `${source.name} resurrected`);
    source.respawn();
    return;
  }

  animateObject("die");

  source.id == "a_sleeping_angel" && (source.id = "sleeping_angel");

  let repeat = 1;
  if (store.state.player.prestige >= 6) {
    repeat = checkOverkill(source, ammount);
    if (
      store.state.player.counter[source.id] + repeat >
      getLast(source.max, target.prestige)
    ) {
      repeat =
        getLast(source.max, target.prestige) -
        store.state.player.counter[source.id];
    }
  }

  SingleCalculation(source, target, repeat);

  for (let index = 0; index < repeat; index++) {
    if (target.counter[source.id] == null) {
      target.counter[source.id] = 0;
    }
    if (target.allcount[source.id] == null) {
      target.allcount[source.id] = 0;
    }
    target.counter[source.id]++;
    target.allcount[source.id]++;
  }

  checkUnlockedItems(target);
  checkHighscore(source, target);
  makeLog(source, target, repeat);

  source.respawn();
  checkCleared(target, source);
}
function makeLog(source, target, repeat) {
  if (!source.boss) {
    let text = `<hr/>${source.name} was killed`;
    repeat > 1 && (text += ` ${repeat} times`);
    log("death", text);
    return;
  } else {
    let text = `<hr/>${source.name} was killed in ${target.time} seconds`;
    repeat > 1 && (text += ` ${repeat} times`);
    log("death", text);
  }
}
function checkHighscore(source, target) {
  if (!source.boss) {
    return;
  }
  try {
    if (
      target.highscore[source.id] == undefined ||
      target.time < target.highscore[source.id]
    ) {
      target.highscore[source.id] = target.time;
    }
    source.id == getLastBoss(target) && store.dispatch("gameWon");
  } catch (e) {
    console.log("some error with boss");
    console.error(e);
  }
}

function checkUnlockedItems(target) {
  let itemlist = store.getters.getItems;

  for (let e of itemlist.filter((x) => !target.unlocked.includes(x.id))) {
    if (e.req && (target.allcount[e.req.id] * 100) / e.req.count >= 100) {
      log("basic", `${e.name} was unlocked`);
      target.unlocked.push(e.id);
    }
  }
}

function checkCleared(target, source) {
  if (target.counter[source.id] >= getLast(source.max, target.prestige))
    store.dispatch("nextStage");
}
