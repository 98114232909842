export function getBlock(target) {
  return target.effects && target.effects.block ? target.effects.block : 0;
}

export function getLifesteal(target, dmg) {
  if (target.effects && target.effects.lifesteal) {
    let ammount = dmg * (target.effects.lifesteal / 100);

    target.clife + ammount >= target.life &&
      (ammount = target.life - target.clife);

    return ammount;
  }
  return 0;
}

export function getReflect(source, damage) {
  if (source.effects && source.effects.reflect) {
    return damage * (source.effects.reflect / 100);
  }
  return 0;
}
