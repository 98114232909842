import { createWebHashHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/dungeon",
  },
  {
    path: "/dungeon",
    name: "Dungeon",
    component: () =>
      import(/* webpackChunkName: "fight" */ "../views/Dungeon.vue"),
  },
  {
    path: "/fight",
    name: "Fight",
    component: () =>
      import(/* webpackChunkName: "fight" */ "../views/Fight.vue"),
  },
  {
    path: "/log",
    name: "Log",
    component: () => import(/* webpackChunkName: "fight" */ "../views/Log.vue"),
  },
  {
    path: "/stats",
    name: "Stats",
    component: () =>
      import(/* webpackChunkName: "fight" */ "../views/Stats.vue"),
  },
  {
    path: "/quests",
    name: "Quests",
    component: () =>
      import(/* webpackChunkName: "quests" */ "../views/Quests.vue"),
  },
  {
    path: "/challenges",
    name: "Challenges",
    component: () =>
      import(/* webpackChunkName: "challenges" */ "../views/Challenges.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  mode: "hash",
});

export default router;
