import store from "@/store/index";
import { RoundToInt, secondsToTime } from "../helper";

const chanceTable = [
  { id: "fire", color: "orange", verb: "burned" },
  { id: "bleed", color: "crimson", verb: "sliced" },
  { id: "poison", color: "green", verb: "poisoned" },
  { id: "disarm", color: "yellow", verb: "disarmed" },
  { id: "rot", color: "darkred", verb: "rotted" },
  { id: "slow", color: "lightGrey", verb: "slowed" },
  { id: "stun", color: "lightYellow", verb: "stunned" },
  { id: "silence", color: "grey", verb: "silenced" },
  { id: "invert", color: "white", verb: "inverted" },
  { id: "stim", color: "green", verb: "stimmed" },
  { id: "bury", color: "brown", verb: "buried" },
  { id: "ward", color: "purple", verb: "warded" },
];

export function logChanceDamage(name, target, damage) {
  if (damage < 0.0) {
    return;
  }
  let obj = chanceTable.find((x) => name == x.id);
  let text = "";
  try {
    text += `${target.name} got  <span style="color:${obj.color}">${RoundToInt(
      damage
    )}</span> ${name} damage`;
  } catch {
    console.error(obj + " not found " + name);
  }

  log("dot", text);

  let clife = 0 < target.clife ? RoundToInt(target.clife) : 0;
  log("detail", `${target.name}  (${clife}/${RoundToInt(target.life)}) `);
}

export function logChance(name, target, ammount = 1) {
  let obj = chanceTable.find((x) => name == x.id);
  let text = "";
  try {
    text += `${target.name} was <span style="color:${obj.color}">${obj.verb} `;
  } catch {
    console.error(obj + " not found " + name);
  }

  text += ammount > 1 ? `${ammount} times</span>` : `</span>`;
  log("chances", text);
}

const critTable = [
  { id: 1, result: "physical", color: "#DA8252" },
  { id: 2, result: "crit", color: "yellow" },
  { id: 4, result: "supercrit", color: "purple" },
  { id: 8, result: "megacrit", color: "red" },
  { id: 16, result: "ultracrit", color: "chartreuse" },
];

export function logPhysical(type, target, ammount) {
  ammount = RoundToInt(ammount);

  let obj = critTable.find((x) => x.id == type);
  let text = "";
  try {
    text += `${target.name} takes <span style="color:${obj.color}">${ammount}</span> ${obj.result} damage`;
  } catch (error) {
    console.error(type + " not found" + obj);
  }

  let category = type == 1 ? "basics" : "crit";
  log(category, text);

  let clife = 0 < target.clife ? RoundToInt(target.clife) : 0;

  log("detail", `${target.name}  (${clife}/${RoundToInt(target.life)}) `);
}

export function log(type, text) {
  let time = secondsToTime(store.state.player.time);
  let displayText = text;

  if (displayText.includes("<hr/>")) {
    displayText = displayText.replace("<hr/>", `<hr/>${time}: `);
  } else {
    displayText = time + ": " + displayText;
  }

  store.commit("addLog", { type, text: displayText, time });
}
