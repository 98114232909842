import {
  matchProperties,
  hasAnySubstring,
  matchEnemy,
  secondsToTime,
} from "@/assets/scripts/helper";

export default {
  getTime(state) {
    return secondsToTime(state.player.time);
  },
  getChallenges: (state) => state.data.challenges,
  getQuests: (state) => state.data.quests,
  getEnemies: (state) => state.data.enemys,
  getChangelog: (state) => state.data.changelog,
  getItems: (state) => state.data.items,
  getBuffs: (state) => state.data.buffs,
  getTipps: (state) => state.data.tipps,
  getChoises: (state) => state.data.choises,
  getSkillTree: (state) => state.data.skilltree,
  currentChallenge(state) {
    return state.data.challenges.find((x) => x.id == [state.player.challenge]);
  },
  checkBuffedEnemies(state, getters) {
    if (getters.currentChallenge) {
      let l = state.player.challenges[state.player.challenge]
        ? state.player.challenges[state.player.challenge]
        : 0;
      l++;
      l++;
      return getters.getBuffedEnemies(state.player.challenge, l);
    }

    return getters.getEnemies.filter((x) => !x.secret);
  },
  getBuffedEnemies: (state, getters) => (val, l) => {
    let c = state.data.challenges.find((x) => x.id == val);
    let ctype = c.effect.type;

    let ammount = l;

    let list = [];
    for (let enemy of getters.getEnemies) {
      let cEnemy = JSON.parse(JSON.stringify(enemy));
      let isBuffed = false;

      for (let cha in enemy[ctype]) {
        cEnemy[ctype][cha] *= ammount;
        isBuffed = true;
      }

      for (let cha in enemy.gain[ctype]) {
        cEnemy.gain[ctype][cha] /= ammount;
        isBuffed = true;
      }

      isBuffed && (cEnemy.buffed = ammount);
      list.push(cEnemy);
    }
    return list;
  },
  noSecrets(state, getters) {
    return getters.checkBuffedEnemies.filter((x) => !x.secret);
  },
  getSearchedEnemys(state, getters) {
    let s = state.search;
    return getters.noSecrets.filter((cEnenmy) => {
      if (!s) {
        return true;
      }

      if (hasAnySubstring(cEnenmy, s)) {
        return true;
      }

      if (matchEnemy(cEnenmy, s)) {
        return true;
      }

      return false;
    });
  },
  getPrestigeEnemys(state, getters) {
    return getters.getSearchedEnemys.filter((cEnenmy) => {
      if (cEnenmy.prestige != null && cEnenmy.prestige != undefined) {
        return state.player.prestige >= cEnenmy.prestige;
      }
      return true;
    });
  },
  getSortedArray(state, getters) {
    return getters.getPrestigeEnemys.sort(
      (a, b) => state.enemyOrder.indexOf(a) - state.enemyOrder.indexOf(b)
    );
  },
  getPlayerEnemyStats: function (state) {
    let filtred = JSON.parse(JSON.stringify(state.player));

    if (!state.enemy) {
      return null;
    }

    let res = Object.fromEntries(
      Object.keys(state.enemy.gain).map((k) => [k, filtred[k]])
    );

    res.speed && (res.sspeed = filtred.sspeed);

    res.chance = matchProperties(filtred.chance, state.enemy.gain.chance);
    res.effects = matchProperties(filtred.effects, state.enemy.gain.effects);
    res.resistance = matchProperties(
      filtred.resistance,
      state.enemy.gain.resistance
    );

    return res;
  },
};
