import { checkAbilityChance } from "../main"

function returnIfExist(obj, category, name, val = 0) {
    return obj[category] && obj[category][name] ? obj[category][name] : val
}

export function getDoge(target, source) {
    let precision = returnIfExist(target, "effects", "precision"),
        dodge = returnIfExist(source, "chance", "dodge");

    return dodge - precision;
}

export function getCrit(target) {
    if (!checkAbilityChance(target, "crit")) {
        return 1;
    }
    if (!checkAbilityChance(target, "supercrit")) {
        return 2
    }
    if (!checkAbilityChance(target, "megacrit")) {
        return 4
    }
    if (!checkAbilityChance(target, "ultracrit")) {
        return 8
    }
    return 16
}





