import { getLast } from "./helper";
import { Enemy } from "./classes/enemy";
import store from "@/store/index";

export function recover(player) {
  function getRegeneration(player) {
    let getPositive = (val) => (0 < val ? val : 0);

    let reg = getPositive(player.recovery) + getPositive(player.regeneration);
    return 1 > reg ? 1 : reg;
  }

  return new Promise((resolve) => {
    player.resetStatus();
    player.cspeed = 0;
    player.clife < 0 && (player.clife = 0);
    let reg = getRegeneration(player) / 10;

    if (reg + player.clife < player.life) {
      store.commit("regenerateLife", reg);
    } else {
      store.commit("setLife", player.life);
      resolve();
    }
  });
}

export function show(p) {
  let pl = JSON.parse(JSON.stringify(p));
  delete pl.name;
  delete pl.challenges;
  delete pl.percent;
  delete pl.finishtime;
  delete pl.challenge;
  delete pl.quests;
  delete pl.gold;
  delete pl.status;
  delete pl.counter;
  delete pl.go;
  delete pl.unlocked;
  delete pl.allcount;
  delete pl.skills;
  delete pl.time;
  delete pl.items;
  delete pl.auto;
  delete pl.debug;
  delete pl.tutorial;
  delete pl.order;
  delete pl.cspeed;
  delete pl.clife;
  delete pl.lastEnemy;
  delete pl.resistance;
  delete pl.version;
  delete pl.effects;
  delete pl.highscore;
  delete pl.chance;
  return pl;
}

export function getEnemy(t) {
  return new Enemy(t).respawn();
}

export function SingleCalculation(obj, player, m = 1) {
  function addOrCreate(player, obj) {
    for (let b in obj) {
      if (player[b] == null) {
        player[b] = obj[b] * m;
      } else {
        player[b] += obj[b] * m;
      }
    }
  }

  function calcSpeed() {
    for (let i = 0; i < m; i++) {
      if (obj.gain.speed > 0) {
        if (player.speed - obj.gain.speed < 110) {
          player.speed = 110;
          player.sspeed += obj.gain.speed;
        } else {
          player.speed -= obj.gain.speed;
        }
      } else {
        if (player.sspeed + obj.gain.speed >= 0) {
          player.sspeed += obj.gain.speed;
        } else {
          player.speed -= obj.gain.speed;
        }
      }
    }
  }

  function calcHealth() {
    if (player.life + obj.gain.life * m <= 1) {
      player.life = 1;
    } else {
      player.life += obj.gain.life * m;
    }
  }

  for (let gain in obj.gain) {
    switch (gain) {
      case "life":
        calcHealth(gain);

        break;
      case "effects":
        addOrCreate(player.effects, obj.gain.effects);
        break;
      case "chance":
        addOrCreate(player.chance, obj.gain.chance);
        break;
      case "resistance":
        addOrCreate(player.resistance, obj.gain.resistance);
        break;
      case "speed":
        calcSpeed(gain);
        break;

      default:
        player[gain] += obj.gain[gain] * m;
        break;
    }
  }
}

export function getNextEnemy() {
  let player = store.state.player;

  if (player.prestige >= 3) {
    for (let ind of player.order) {
      if (ind == "a_sleeping_angel") {
        continue;
      }
      let e = store.getters.checkBuffedEnemies.find((e) => e.id == ind);
      if (player.counter[e.id] < getLast(e.max, player.prestige)) {
        return e;
      }
    }
  } else {
    let last = store.getters.checkBuffedEnemies.find(
      (e) => e.id == player.lastEnemy
    );
    if (last != undefined) {
      if (player.counter[last.id] < getLast(last.max, player.prestige)) {
        return last;
      } else {
        player.auto = false;
        return null;
      }
    }
  }
}

export function getLastBoss(t) {
  let bosses = store.getters.getEnemies.filter((x) => x.boss);
  if (t.prestige < 6) {
    return bosses[t.prestige].id;
  } else {
    return bosses[bosses.length - 1].id;
  }
}
