export default {
  setHidden(state, val) {
    state.hidden = val;
  },
  setSearch(state, val) {
    state.search = val;
  },
  setDragEl(state, val) {
    state.dragEl = val;
  },
  countTime(state) {
    state.player.time += 0.1;
  },
  setTimer(state, val) {
    state.timer.push(val);
  },
  setTimerClock(state, val) {
    state.timer.clock = val;
  },
  setTimerSave(state, val) {
    state.timer.save = val;
  },
  setTimerRecovery(state, val) {
    state.timer.recovery = val;
  },
  setEnemy(state, val) {
    state.enemy = val;
  },
  abbortChallenge(state) {
    state.player.challenge = null;
    state.player.reset();
  },
  startChallenge(state, val) {
    state.player.challenge = val;
  },
  recalculatePlayer(state) {
    state.player.reset();
  },
  setData(state, val) {
    state.data = val;
  },
  finishQuest(state, val) {
    let inx = state.player.quests.indexOf(val);
    if (inx === -1) {
      state.player.quests.push(val);
      state.player.gold += state.data.quests.find((x) => x.id == val).gold;
    }
  },
  setPlayer(state, val) {
    state.player = val;
  },
  setImages(state, val) {
    state.images = val;
  },
  setLife(state, val) {
    state.player.clife = val;
  },
  addLog(state, val) {
    const threshold = 800;
    if (state.log.length > 1000) {
      state.log.splice(0, state.log.length - threshold);
    }
    state.log.push(val);
  },
  regenerateLife(state, val) {
    state.player.clife += val;
  },
  looseLife(state, val) {
    state.player.clife = val;
  },
  setOrder(state, order) {
    state.player.order = order;
  },
  setFightTimer(state, t1) {
    state.timer.fight = t1;
  },
  toggleLog(state, v) {
    state.openedLog[v] = !state.openedLog[v];
  },
  clearFightTimer(state) {
    clearInterval(state.pTimer);
  },
  setOverlay(state, val) {
    state.overlay = val;
  },
  setSpeed(state, val) {
    state.GameSpeed = val;
  },
  clearRecoveryTimer(state) {
    state.timer.recovery = clearInterval(state.timer.recovery);
  },
  setEnemyOrder(state, val) {
    state["enemyOrder"] = [...val];
  },
  clearLog(state) {
    state.log = [];
  },
};
