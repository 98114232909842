import { checkDebuffTurn } from "../debuff";
import { checkDeath } from "../death";
import { getDoge, getCrit } from "./chance";
import { getBlock, getReflect, getLifesteal } from "./effects";
import { changeLife } from "../damage";
import { checkChance, checkAbilityChance } from "../main";
import { log, logPhysical } from "../log";
import { RoundToInt } from "../../helper";

function CheckInstaKill(target, source) {
  if (checkAbilityChance(target, "instakill")) {
    if (!source.boss) {
      log(
        "chances",
        `${target.name} <span style="color:purple">instakilled</span> ${source.name}`
      );

      changeLife(source, source.clife + 10, "instakill", "damage");
    } else if (source.boss && target.items.includes("helosword")) {
      log(
        "chances",
        `${
          target.name
        } tryed to <span style="color:purple">instakill</span> with the Sword ${
          source.name
        } doing ${source.life / 1.25} Damage`
      );
      changeLife(source, source.life / 1.25, "instakill", "damage");
    } else {
      log(
        "chances",
        `${target.name} tryed to <span style="color:purple">instakill</span> ${
          source.name
        } doing ${source.life / 4} Damage`
      );
      changeLife(source, source.life / 4, "instakill", "damage");
    }

    checkDeath(target, source);
    return true;
  }
}

export function checkAttack(target, source) {
  if (CheckInstaKill(target, source)) {
    return;
  }

  if (checkDebuffTurn(target, "disarm")) {
    log(
      "chances",
      `${target.name} is <span style="color:yellow">disarmed</span> and does not attack`
    );
    return;
  }

  if (checkChance(getDoge(target, source))) {
    log("chances", `${source.name} <span style="color:brown">dodged</span>`);
    checkDeath(target, source);
    return;
  }

  if (checkAbilityChance(source, "counter")) {
    log("chances", `${source.name} countered the attack`);
    checkDamage(target, source);
    return;
  }

  let dmg = checkDamage(source, target);

  if (checkAbilityChance(target, "double")) {
    log("chances", `${target.name} attacks second time`);
    checkDamage(source, target);
  }

  if (!checkDeath(target, source, dmg)) {
    return;
  }

  return false;
}

function chekReflectDamage(source, target, dmg) {
  let reflect = getReflect(source, dmg);
  if (reflect > 0) {
    changeLife(target, reflect, "reflect", "damage");

    log(
      "effect",
      `${target.name} got <span style="color:grey">${RoundToInt(
        reflect
      )}</span> damage as reflect from ${source.name}`
    );
  }
}

function checkLifeSteal(target, dmg) {
  let steal = getLifesteal(target, dmg);

  if (steal > 0) {
    changeLife(target, steal, "lifesteal", "heal");
    log(
      "effect",
      `${target.name} got <span style="color:crimson">${RoundToInt(
        steal
      )}</span> heal as lifesteal`
    );
  }
}

function checkBlock(source, target) {
  let block = getBlock(source);
  if (block > 0) {
    log(
      "effect",
      `${target.name} blocked <span style="color:darkgrey">${RoundToInt(
        block
      )}</span> damage`
    );
  }
  return block;
}

function checkDamage(source, target) {
  let crit = getCrit(target);
  let dmg = target.dmg * crit;

  chekReflectDamage(source, target, dmg);

  let block = checkBlock(source, target, dmg);
  dmg -= block;

  if (dmg > 0.0) {
    changeLife(source, dmg, "crit" + crit, "damage");
    logPhysical(crit, source, dmg);

    checkLifeSteal(target, dmg);
  } else {
    log("basics", `${target.name} can't do any damage to ${source.name} `);

    log(
      "detail",
      `${target.name} damage = ${RoundToInt(target.dmg * crit)} 
      - ${source.name} block ${RoundToInt(block)}`
    );
  }
  return dmg;
}
