import { Creature } from "./creature";

export class Enemy extends Creature {
  respawn() {
    if (this.id == "a_sleeping_angel") {
      this.id = "sleeping_angel";
    }
    if (this.id == "sleeping_angel") {
      let rand = Math.floor(Math.random() * 100);
      if (rand >= 95) {
        this.id = "a_sleeping_angel";
      }
    }
    return super.respawn();
  }

  constructor(obj) {
    super(obj);
  }
}
