/*eslint no-prototype-builtins: "error"*/
import { checkDebuffs, checkSpeedDebuffs, checkDebuffTurn } from "./debuff";

import { checkAttack } from "./physical/main";
import { checkSpells } from "./magic/main";

import { regenarate } from "./regenerate";
import { animateObject } from "./display";
import { checkDeath } from "./death";

function checkTurn(target) {
  if (target == null) {
    return true;
  }
  let sp = 100;

  0 < target.status.slow && (sp -= 50);
  0 < target.status.stim && (sp += 50);

  target.cspeed += sp;

  if (target.cspeed >= target.speed) {
    target.cspeed = 0;
    return false;
  }
  return true;
}

export function update(target, source) {
  regenarate(target);

  checkDebuffs(target, source);

  if (!checkDeath(target, source)) {
    return;
  }

  if (checkTurn(target)) {
    return;
  }

  checkSpeedDebuffs(target, source);

  checkDebuffTurn(target, "invert");
  checkDebuffTurn(target, "bury");

  if (!checkDeath(target, source)) {
    return;
  }

  if (checkDebuffTurn(target, "stun")) {
    if (!checkDeath(target, source)) {
      return;
    }
    return;
  }

  checkSpells(target, source);

  checkAttack(target, source);

  target.version != null && animateObject("animated");

  if (!checkDeath(target, source)) {
    return;
  }
}

export function checkAbilityChance(obj, name) {
  if (!obj.chance || !obj.chance[name]) {
    return false;
  }
  let c = obj.chance[name];
  if (
    obj.version != null &&
    obj.challenges["chance"] &&
    obj.challenge != "chance"
  ) {
    c *= 1 + obj.challenges["chance"] / 100;
  }
  return checkChance(c);
}

export function checkAbilityChanceWithNegativ(target, name, source, name2) {
  if (!target.chance || !target.chance[name]) {
    return false;
  }
  let c2 = 0;
  if (!source.chance || !source.chance[name2]) {
    c2 = 0;
  } else {
    c2 = source.chance[name2];
  }
  let c = target.chance[name];
  if (
    target.version != null &&
    target.challenges["chance"] &&
    target.challenge != "chance"
  ) {
    c *= 1 + target.challenges["chance"] / 100;
  }
  c -= -c2;
  return checkChance(c);
}

export function checkChance(val) {
  return Math.floor(10001 * Math.random()) <= 100 * val;
}
