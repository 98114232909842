import { Player } from "@/assets/scripts/classes/player";

export function getPlayer(p) {
  if (p != null) {
    return new Player(p);
  } else {
    let obj = JSON.parse(localStorage.getItem("saveGame"));
    return new Player(obj);
  }
}

export function savePlayer(player) {
  if (typeof Storage !== "undefined") {
    let save = JSON.parse(JSON.stringify(player));

    delete save.effects;
    delete save.chance;
    delete save.resistance;
    delete save.life;
    delete save.clife;
    delete save.speed;
    delete save.cspeed;
    delete save.magic;
    delete save.regeneration;
    delete save.recovery;
    delete save.dmg;
    delete save.status;
    delete save.points;
    delete save.sspeed;

    localStorage.setItem("saveGame", JSON.stringify(save));
  }
}

export function copyToClipboard(text) {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  let filename = "AbsorberSave" + date;
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
