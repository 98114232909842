import { showIndicator } from "./display";
import { RoundToInt } from "../helper";

export function changeLife(target, ammount, category, type) {
  ammount = RoundToInt(ammount);

  if (isNaN(ammount) || ammount <= 0) return;

  if (type == "damage") {
    dealDamage(target, ammount, category);
  } else if (type == "heal" || type == "regeneration") {
    heal(target, ammount);
  } else {
    console.error(type);
  }
}

function dealDamage(target, ammount, category) {
  target.clife -= ammount;
  !target.version && showIndicator(ammount, category);
}

function heal(target, ammount) {
  target.clife += ammount;
}
