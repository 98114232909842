import { SingleCalculation, getLastBoss } from "@/assets/scripts/game";
import {
  isEmpty,
  getNodeById,
  hasDuplicates,
  getLast,
} from "@/assets/scripts/helper";
import store from "@/store/index";
import { Creature } from "./creature";
import { p } from "./standartPlayer";

export class Player extends Creature {
  constructor(obj) {
    super(obj);
    if (isEmpty(obj)) {
      this.hardReset();
    }
    this.reset();
  }

  hardReset() {
    return new Promise((resolve) => {
      for (let i in JSON.parse(JSON.stringify(p))) this[i] = p[i];

      this.version = p.version;

      this.setCounter();

      resolve(this);
    });
  }

  setCounter() {
    let enemieslist = store.getters.getEnemies;

    if (isEmpty(this.allcount)) {
      this.allcount = {};
      for (let en of enemieslist) this.allcount[en.id] = 0;
    }

    if (isEmpty(this.counter)) {
      this.counter = {};
      for (let en of enemieslist) this.counter[en.id] = 0;
    }

    //Reset order if new Enemys
    if (
      this.order == undefined ||
      this.order.length <= 0 ||
      hasDuplicates(this.order)
    ) {
      this.order = enemieslist.map(({ id: a }) => a);
    }

    //Reset push new enemies into order
    while (enemieslist.length > this.order.length) {
      this.order.push(enemieslist[this.order.length].id);
    }
  }

  prestgeUp() {
    this.prestige++;
    this.softReset();
  }

  softReset() {
    for (let en of store.getters.getEnemies) this.counter[en.id] = 0;

    this.reset();

    this.time = 0;
    this.go = false;
  }

  reset() {
    return new Promise((resolve) => {
      let save = JSON.parse(JSON.stringify(this));

      this.hardReset();

      this.name = save.name;
      this.counter = save.counter;

      for (let en of store.getters.getEnemies)
        if (this.counter[en.id] == null) this.counter[en.id] = 0;

      this.prestige = save.prestige;

      this.quests = save.quests;

      this.allcount = save.allcount;
      this.items = save.items;
      this.skills = save.skills;
      this.highscore = save.highscore;
      this.unlocked = save.unlocked;
      this.auto = save.auto;
      this.finishtime = save.finishtime;

      this.lastEnemy = save.lastEnemy;
      this.time = save.time;
      this.gold = save.gold;

      this.challenge = save.challenge;

      this.challenges = save.challenges;

      this.order = save.order;

      for (let val in p) {
        if (this[val] == undefined) {
          this[val] = p[val];
        }
      }

      for (let val in this) {
        if (!Object.hasOwn(p, val)) {
          delete this[val];
        }
      }

      this.chance = {};
      this.effects = {};
      this.resistance = {};

      this.recalculate();
      resolve(this);
    });
  }

  recalculate() {
    return new Promise((resolve) => {
      var startTime = performance.now();

      let enemieslist;
      if (this.challenge != null) {
        let l = this.challenges[this.challenge]
          ? this.challenges[this.challenge]
          : 0;
        l++;
        l++;

        enemieslist = store.getters.getBuffedEnemies(this.challenge, l);
      }

      if (enemieslist == null) {
        enemieslist = store.getters.getEnemies;
      }

      this.setCounter();

      //multiply Calculation
      for (let d in this.counter) {
        let m = this.counter[d];
        if (m == 0) {
          continue;
        }

        let e = enemieslist.find((a) => a.id === d);

        if (m > getLast(e.max, this.prestige)) {
          this.counter[d] = getLast(e.max, this.prestige);
        }

        e != undefined && SingleCalculation(e, this, this.counter[d]);
      }

      for (let b of this.skills) {
        let a = getNodeById(b, store.getters.getSkillTree);
        let c = store.getters.getChoises.find((b) => b.id === a.typ);

        SingleCalculation(c, this);
      }

      if (null != this.items) {
        for (let d of this.items) {
          let e = store.getters.getItems.find((a) => a.id === d);
          e != null && SingleCalculation(e, this);
        }
      }

      //Calculation Points via Prestige and Skills
      this.points = this.prestige - this.skills.length;

      //Checking if lastboss is killed
      if (0 < this.counter[getLastBoss(this)]) {
        this.go = true;
      }

      //check if too much items
      if (null != this.items && this.maxitems < this.items.length) {
        let w = this.items.length - this.maxitems;
        this.items.splice(-w, w);
      }

      this.respawn();

      var endTime = performance.now();
      console.log(`Recalculation took ${endTime - startTime} milliseconds`);

      resolve(this);
    });
  }
}
