export function isEmpty(obj) {
  if (obj == null) {
    return true;
  }
  return Object.keys(obj).length === 0;
}

export function reverse(str) {
  return str.split("").reverse().join("");
}

export function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function RoundToInt(e) {
  return Math.round(100 * (e + Number.EPSILON)) / 100;
}

export function getLast(j, p) {
  if (p >= j.length) {
    return j[j.length - 1];
  } else {
    return j[p];
  }
}

export function getNodeById(id, node) {
  var reduce = [].reduce;
  function runner(result, node) {
    if (result || !node) return result;
    return (
      (node.id === id && node) ||
      runner(null, node.open) ||
      reduce.call(Object(node), runner, result)
    );
  }
  return runner(null, node);
}

export function getNext(db, key) {
  for (var i = 0; i < db.length; i++) {
    if (db[i].key === key) {
      return db[i + 1] && db[i + 1].value;
    }
  }
}

export function matchProperties(obj, enemyp) {
  if (enemyp) {
    let keys = Object.fromEntries(Object.keys(enemyp).map((k) => [k, obj[k]]));
    obj = Object.fromEntries(Object.entries(keys).filter(([, v]) => v != null));
  } else {
    return {};
  }
  return obj;
}

function arrayHasSubstring(array, substring) {
  if (array == null) {
    return false;
  }

  for (let keyName of Object.keys(array))
    if (keyName.indexOf(substring) != -1) {
      return true;
    }

  return false;
}

export function hasAnySubstring(enemy, search) {
  return (
    arrayHasSubstring(enemy.gain, search) ||
    arrayHasSubstring(enemy.gain.chance, search) ||
    arrayHasSubstring(enemy.gain.effects, search) ||
    arrayHasSubstring(enemy.gain.resistance, search)
  );
}

export function matchEnemy(enemy, search) {
  return enemy.name.match(new RegExp(search, "i"));
}

export function secondsToTime(time) {
  let milliseconds = Math.floor(time * 1000);
  let hours = Math.floor(milliseconds / 3600000);
  let minutes = Math.floor((milliseconds % 3600000) / 60000);
  let seconds = Math.floor((milliseconds % 60000) / 1000);
  let microseconds = Math.floor((milliseconds % 1000) / 100);

  let timeString = "";

  if (hours > 0) {
    hours = hours < 10 ? "0" + hours : hours;
    timeString += `${hours}:`;
  }

  if (hours > 0 || minutes > 0) {
    minutes = minutes < 10 ? "0" + minutes : minutes;
    timeString += `${minutes}:`;
  }

  seconds = seconds < 10 ? "0" + seconds : seconds;
  timeString += `${seconds}.`;

  timeString += microseconds;

  return timeString;
}
